<template>
    <a-modal
        title="详情"
        :width="900"
        :visible="visible"
        :destroyOnClose="true"
        @cancel="handleCancel"
        :footer="null"
    >
        <a-spin :spinning="confirmLoading">
            <a-row>
                <a-form layout="inline">
                    <a-col>
                        <a-row>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="商家昵称">
                                    <span>{{details.nickname}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="手机号">
                                    <span>{{details.mobile}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="ID号">
                                    <span>{{details.idNo}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="店铺关注">
                                    <span>{{details.followNum}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="认证类型">
                                    <span>{{details.type==1?'个人':details.type==2?'个体工商户':details.type==3?'公司企业':''}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="经营类目">
                                    <span>{{details.categoryName}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="身份证号">
                                    <span>{{details.idNumber}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="注册时间">
                                    <span>{{details.registerTime}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="开店时间">
                                    <span>{{details.createTime}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="营业执照名称">
                                    <span>{{details.businessLicenseName}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="营业执照注册号">
                                    <span>{{details.businessLicenseNo}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col v-if="details.businessLicenseImages" :md="24" :sm="24">
                                <a-form-item  label="营业执照照片">
                                    <viewer :images="details.businessLicenseImages.split(',')">
                                        <img 
                                            v-for="(item, index) in details.businessLicenseImages.split(',')" 
                                            :key = "index"
                                            :src="item" 
                                            alt=""
                                            style="border:0.5px solid #ccc; padding:3px; margin:3px;"
                                        >
                                    </viewer>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="身份证正面">
                                    <viewer :images="[details.idFront]" v-if="details.idFront">
                                        <img 
                                            :src="details.idFront" 
                                            alt=""
                                            style="border:0.5px solid #ccc; padding:3px; margin:3px;"
                                        >
                                    </viewer>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="身份证反面">
                                    <viewer :images="[details.idBack]" v-if="details.idBack">
                                        <img 
                                            :src="details.idBack" 
                                            alt=""
                                            style="border:0.5px solid #ccc; padding:3px; margin:3px;"
                                        >
                                    </viewer>
                                </a-form-item>
                            </a-col>
                            <a-col :md="24" :sm="24">
                                <a-form-item  label="个性签名">
                                    <span>{{details.signature}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-form>
            </a-row>
        </a-spin>
    </a-modal>
</template>

<script>
import { 
  storeAdminPageList, 
  storeAdminDetail, 
  storeAdminDelete
} from '@/api/modular/mallLiving/report'

const columns = [
  { 
    title: '序号', 
    align: 'center', 
    width:'80px',
    key:'number',
    customRender: (text,record,index) => `${index+1}`
  },
  { 
    title: '商品名称', 
    align: 'center', 
    dataIndex: 'goodsName', 
    width:'200px',
    key: 'goodsName', 
  },
  {
    title:"商品图片",
    dataIndex: 'goodsImage',
    width:'100px',
    key: 'goodsImage',
    align:"center",
    scopedSlots: { customRender: 'goodsImage' }
  },
  {
    title:"规格类型",
    dataIndex: 'speType',
    width:'80px',
    key: 'speType',
    align:"center",
    scopedSlots: { customRender: 'speType' }
  },
  {
    title:"规格",
    dataIndex: 'specName',
    width:'120px',
    key: 'specName',
    align:"center"
  },
  {
    title:"规格图片",
    dataIndex: 'specImage',
    width:'120px',
    key: 'specImage',
    align:"center",
    scopedSlots: { customRender: 'specImage' }
  },
  {
    title:"购买数量",
    dataIndex: 'buyNum',
    width:'80px',
    key: 'buyNum',
    align:"center",
  },
  {
    title:"单价（元）",
    dataIndex: 'unitPrice',
    width:'80px',
    key: 'unitPrice',
    align:"center",
  },
  {
    title:"创建时间",
    dataIndex: 'createTime',
    width:'150px',
    key: 'createTime',
    align:"center",
  },
];

export default {
    data(){
        return {
            columns,
            data:[],
            visible: false, //modal框显示状态
            confirmLoading: false,
            id:'',
            details:{},
            form: this.$form.createForm(this),
        }
    },
    methods:{
        //初始化方法
        detail(record){
            this.visible = true
            this.confirmLoading = true

            //获取详情数据
            setTimeout(()=>{
                storeAdminDetail({id: record.id}).then((res)=>{
                    if(res.success){
                        this.details = res.data
                        this.data = res.data.orderGoodsList
                        this.confirmLoading = false
                    }
                }).finally((res)=>{
                    setTimeout(()=>{
                        this.confirmLoading = false
                    },5000)
                })
            },100)
            
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            this.confirmLoading = false
            setTimeout(()=>{
                this.details = {} //关闭之后清空
            },100)
        }
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding:7px;
            font-size:15px;
            width:30%;
        }
    }
    img{
        width:80px;
    }
</style>