<template>
  <!-- 商家管理页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="6" :sm="24">
                  <a-form-item label="企业名称：">
                    <a-input v-model="queryParam.name" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col>
                <!-- 状态 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="状态">
                    <a-select v-model="queryParam.delFlag" allow-clear placeholder="请选择">
                      <a-select-option :value="1">正常</a-select-option>
                      <a-select-option :value="3">禁用</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-button type="primary" @click="$refs.addForm.add()">新增</a-button>
                  <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), initTableData())">重置</a-button>
                  <a-button type="primary" style="margin-left: 8px" @click="initTableData">搜索</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
            :row-selection="rowSelection"
          >
            <!-- 账号状态插槽 -->
            <span slot="delFlag" slot-scope="text">
              <a-tag :color="text == 1 ? 'green' : text == 3 ? 'red' : ''">
                {{ text == 1 ? '正常' : text == 3 ? '禁用' : '' }}
              </a-tag>
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a-popconfirm
                placement="topRight"
                :title="+record.delFlag === 1 ? '确认禁用' : '确认启用'"
                @confirm="() => sysItemDelete(record.id, +record.delFlag === 1 ? 3 : 1)"
              >
                <a style="color: #f5222d" v-if="+record.delFlag === 1">禁用</a>
                <a style="color: #52c41a" v-if="+record.delFlag === 3">启用</a>
              </a-popconfirm>
              <a-divider type="vertical" />

              <a @click="$router.push({ path: '/employeeList', query: { id: record.id } })">员工列表</a>
              <a-divider type="vertical" />
              <a @click="$refs.editForm.init(record)">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm
                placement="topRight"
                title="确认删除当前企业吗？"
                @confirm="() => sysItemDelete(record.id, 2)"
              >
                <a>删除</a>
              </a-popconfirm>
            </span>
          </a-table>
        </div>
      </a-col>
    </a-row>
    <!-- 分页组件 -->
    <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
      <a-pagination
        :pageSize="page.pageSize"
        :current="page.pageNo"
        show-size-changer
        :page-size-options="['10', '20', '50', '100']"
        show-quick-jumper
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="pageChange"
        @showSizeChange="sizeChange"
      >
      </a-pagination>
    </div>
    <!-- 引入子组件 -->
    <detail-form ref="detailForm"></detail-form>
    <add-form @ok="initTableData" ref="addForm"></add-form>
    <edit-form @ok="getList" ref="editForm"></edit-form>
  </div>
</template>

<script>
import { releaseLiveForbidden } from '@/api/modular/mallLiving/report'

import addForm from './addForm'
import editForm from './editForm'
import detailForm from './detailForm.vue'
import { enterpriseDelete, enterprisePageList } from '@/api/modular/mallLiving/enterprises'
const columns = [
  {
    title: '序号',
    align: 'left',
    width: '70px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '企业名称',
    align: 'left',
    width: '150px',
    dataIndex: 'name',
  },
  {
    title: '企业联系人',
    width: '100px',
    align: 'left',
    dataIndex: 'linkName',
  },
  {
    title: '联系人手机号',
    width: '100px',
    dataIndex: 'linkMobile',
    align: 'left',
  },
  {
    title: '企业账号',
    width: '130px',
    dataIndex: 'account',
    align: 'left',
  },
  // {
  //   title: '企业密码',
  //   width: '130px',
  //   dataIndex: 'fansNum',
  //   align: 'left',
  // },
  {
    title: '积分余额',
    width: '130px',
    dataIndex: 'integral',
    align: 'left',
  },
  {
    title: '已用积分',
    width: '130px',
    dataIndex: 'hasUseIntegral',
    align: 'left',
  },
  {
    title: '状态',
    width: '100px',
    dataIndex: 'delFlag',
    align: 'left',
    scopedSlots: {
      customRender: 'delFlag',
    },
  },
  {
    title: '注册时间',
    width: '150px',
    dataIndex: 'createTime',
    align: 'left',
  },

  {
    title: '操作',
    align: 'left',
    width: '250px',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action',
    },
  },
]
export default {
  data() {
    return {
      columns,
      data: [],
      recordItem: null,
      total: 0,
      loading: false,
      queryParam: {},
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      selectedRowKeys: [],
      selectRowId: [],
    }
  },
  components: {
    addForm,
    editForm,
    detailForm,
  },
  mounted() {
    this.initTableData()
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectRowId = selectedRows.map((item) => {
            return item.id
          })
        },
      }
    },
  },
  methods: {
    releaseForbidden(record) {
      releaseLiveForbidden({ storeId: record.id }).then((res) => {
        if (res.success) {
          this.$message.success('解禁成功')
          this.getList()
        }
      })
    },

    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: this.queryParam,
        page: this.page,
      }
      this.selectRowId = []
      this.selectedRowKeys = []
      enterprisePageList(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    // 删除
    sysItemDelete(id, delFlag) {
      this.disableCommodityCate(id, delFlag) //2:删除
    },

    async disableCommodityCate(id, delFlag) {
      let params = {
        delFlag: delFlag,
        id: id,
      }
      let data = await enterpriseDelete(params)
      const text = delFlag == 1 ? '启用' : delFlag == 2 ? '删除' : '禁用'
      if (data.code == 200) {
        this.$message.success(text + '成功')
        this.getList()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 40px;
  height: 40px;
}
</style>
